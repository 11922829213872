import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createCss, defaultThemeMap } from "@stitches/react";

var _createCss = createCss({
  themeMap: _objectSpread(_objectSpread({}, defaultThemeMap), {}, {
    font: "fonts"
  }),
  theme: {
    fonts: {
      docs: '500 14px/20px "Inter", -apple-system, sans-serif',
      ui: '500 12px/14px "Inter", -apple-system, sans-serif',
      section: '600 12px/14px "Inter", -apple-system, sans-serif',
      mono: '500 14px/14px "IBM Plex Mono", monospace',
      "mono-ui": '500 12px/14px "IBM Plex Mono", monospace',
      code: '500 14px/18px "IBM Plex Mono", monospace',
      monoheading: '600 20px/24px "IBM Plex Mono", monospace',
      debug: '500 10px/10px "IBM Plex Mono", monospace'
    },
    colors: {
      left: "rgba(29, 144, 255, 1)",
      right: "rgba(255, 141, 30, 1)",
      selected: "rgba(255, 0, 0, 1)",
      hovered: "rgba(255, 0, 0, 0.1)",
      bounds: "rgb(0, 106, 255)",
      bounds_bg: "rgba(0, 106, 255, 0.05)",
      canvas: "rgba(239, 239, 239, 1)",
      panel: "rgba(255, 255, 255, 1)",
      section: "rgba(251, 250, 250, 1)",
      border: "rgba(231, 231, 231, 1)",
      text: "rgba(37, 37, 37, 1)",
      code: "rgba(37, 37, 160, 1)",
      soft: "rgba(255, 255, 255, 0.62)",
      hint: "rgba(0, 0, 0, 0.38)",
      muted: "rgba(0, 0, 0, 0.16)",
      outline: "rgba(0, 0, 0, 1)",
      fill: "rgba(0, 0, 0, 1)",
      lineError: "rgba(255, 0,0,.1)"
    }
  }
}),
    styled = _createCss.styled,
    global = _createCss.global,
    theme = _createCss.theme,
    getCssString = _createCss.getCssString;

export { styled, global, theme, getCssString };
export var dark = theme("dark", {
  colors: {
    canvas: "rgba(29, 29, 28, 1)",
    panel: "rgba(19, 19, 18, 1)",
    section: "rgba(31, 31, 30, 1)",
    border: "rgba(0, 0, 0, 1)",
    text: "rgba(239, 239, 239, 1)",
    code: "rgba(200, 200, 200, 1)",
    soft: "rgba(52, 52, 52, 0.72)",
    hint: "rgba(255, 255, 255, 0.38)",
    muted: "rgba(255, 255, 255, 0.62)",
    outline: "rgba(184, 185, 187, 1)",
    fill: "rgba(255, 255, 255, 1)"
  }
});