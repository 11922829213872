export var GA_TRACKING_ID = process.env.GA_MEASUREMENT_ID; // https://developers.google.com/analytics/devguides/collection/gtagjs/pages

export var pageview = function pageview(url) {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url
  });
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export var event = function event(_ref) {
  var action = _ref.action,
      category = _ref.category,
      label = _ref.label,
      value = _ref.value;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value
  });
};